









































































import { Component } from 'vue-property-decorator';
import ListBase from '@/lib/listbase';
import FormBase from '@/components/helper/form-base.vue';
import Util from '@/lib/util'
import Lesson from '@/store/entities/courses/lesson';

@Component({
    components: { FormBase },
})
export default class PublicMark extends ListBase {
    name: string = 'publicMark';
    lesson: Lesson = new Lesson();        
    student: any = null;        
    marked: boolean = false;        
    link: string = '';
    document: string = '';
    fRules: any = {
        required: value => !!value || this.L('Debe Ingresar su numero de documento'),
        counter: value => value.length <= 8 || 'Max 8 numeros'
    };

    /***** COMPUTED METHODS *****/
    get loadingLesson() {
        return this.$store.state.lesson.loading;
    }

    get loading() {
        return this.$store.state.attendance.loading;
    }

    get loadingStudent() {
        return this.$store.state.student.loading;
    }

    /***** EVENTS *****/
    async checkDocument() {
        if (this.document == null) return;
        if (this.document.length < 8) return;
        let model = await this.$store.dispatch({
            type: 'student/getByDocument',
            data: {
                document: this.document,
                course_class_id: this.lesson.course_class_id,
                lesson_id: this.lesson.id,
            }
        });
        if (model != null && model.id != null) {
            this.student = model.id;
            if (this.lesson.opened && !this.lesson.canceled)
                await this.isMarked();
        }
    }

    async createMark() {
        let model = await this.$store.dispatch({
            type: 'attendance/create',
            data: {
                lesson_id: this.lesson.id,
                student_id: this.student,
            }
        });
        this.lesson.nro_attendees = model.nro_attendees;
        this.marked = model.marked;
        this.link = model.link;
        this.$swal('Asistencia Registrada', 'Tu asistencia esta confirmada, puedes ingresar al curso haciendo click en el link!', 'success');
    }

    /***** FUNCIONES DE INTERFACE *****/
    getInitials(string) {
        return Util.getInitials(string);
    }


    /***** INIT EVENTS *****/
    async loadLesson() {
        let model = await this.$store.dispatch({
            type: 'lesson/get',
            id: this.$route.params.id
        });
        this.$store.commit('lesson/edit', model);
        this.lesson = Util.extend(true, {}, this.$store.state.lesson.editModel);
    }

    async isMarked() {
        let model = await this.$store.dispatch({
            type: 'attendance/isMarked',
            data: {
                lesson_id: this.lesson.id,
                student_id: this.student,
            }
        });
        this.marked = model.marked;
        this.link = model.link;
    }

    async mounted() {
        await this.loadLesson();
        if (this.$route.query.student != undefined) {
            this.student = this.$route.query.student;
            if (this.lesson.opened && !this.lesson.canceled)
                await this.isMarked();
        }
    }

    created() {
        this.$store.commit('enrollment/new');
    }
    
}
